import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
// import { AuthGuardService } from 'app/support/services/auth/auth-guard.service';
import { PatientAssessmentViewComponent } from './patient-assessment-view/patient-assessment-view.component';
// import { PatientAssessmentMobileViewComponent } from './patient-assessment-mobile-view/patient-assessment-mobile-view.component';
import { PatientAssessmentAuthComponent } from './patient-assessment-auth/patient-assessment-auth.component';
import { PatientAssessmentLinkComponent } from './patient-assessment-link.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CompleteAssessmentComponent } from './patient-assessment-view/complete-assessment/complete-assessment.component';
import { PatientAssessmentViewModule } from './patient-assessment-view/patient-assessment-view.module';
import { SharedModule } from '../shared/shared.module';
import { ConfimerComponent } from '../shared/components/dialog-box/confimer/confimer.component';
const PATIENTS_ASSESSMENT_LINK_ROUTES: Routes = [
  {
    path: '',
    component: PatientAssessmentLinkComponent,
    // canActivate: [AuthGuardService],
  },
  {
    path: 'device',
    component: PatientAssessmentLinkComponent,
    // canActivate: [AuthGuardService],
  },
  // {
  //   path: 'view',
  //   component: PatientAssessmentMobileViewComponent,
  //   canActivate: [AuthGuardService],
  // },
  {
    path: 'complete-assessment',
    component: CompleteAssessmentComponent,
    // canActivate: [AuthGuardService],
  },
  // {
  //   path: 'device-take',
  //   component: DevicePatientTakeComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['view#scheduledPatientAssessmentRun'] }
  // },
];

@NgModule({
  declarations: [PatientAssessmentLinkComponent, PatientAssessmentAuthComponent, CompleteAssessmentComponent, ],
  imports: [
    CommonModule,
    RouterModule.forChild(PATIENTS_ASSESSMENT_LINK_ROUTES),
    SharedModule,
    PatientAssessmentViewModule
  ],
  exports: [
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    }
  ],
  entryComponents: [
    ConfimerComponent
  ]
})
export class PatientsAssessmentLinkModule { }
