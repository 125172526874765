import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientAssessmentViewComponent } from './patient-assessment-view.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DeviceDetectorService } from 'ngx-device-detector';


@NgModule({
  declarations: [PatientAssessmentViewComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    PatientAssessmentViewComponent
  ],
  providers: [
    DeviceDetectorService
  ]
})
export class PatientAssessmentViewModule { }
