import { Component, OnChanges, ChangeDetectorRef, Input, Inject, Output, EventEmitter, ViewEncapsulation, ViewChild, ViewChildren, QueryList, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { MatStepper, MatHorizontalStepper } from '@angular/material';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import *  as  statusmessage from 'src/assets/messages/messages_en.json';
import { PatientAssessmentService } from 'src/app/support/services/patient-assessment.service';
import { SnackbarCongiration } from 'src/app/shared/components/snack-bar/SnackbarConfiguration';
import { ConfimerComponent } from 'src/app/shared/components/dialog-box/confimer/confimer.component';
import { ReloadComponent } from 'src/app/shared/components/dialog-box/reload/reload.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'patient-assessment-view',
  templateUrl: './patient-assessment-view.component.html',
  styleUrls: ['./patient-assessment-view.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class PatientAssessmentViewComponent implements OnChanges {

  @Input() assessmentData: any;
  set _assessmentData(assessmentData: any) {
    this.assessmentData = assessmentData;
  }
  @Input() assessment_take_payload: any;
  set _assessment_take_payload(assessment_take_payload: any) {
    this.assessment_take_payload = assessment_take_payload;
  }
  @Output() emitAssessmentComplete = new EventEmitter<any>();
  @ViewChildren('tooltip') tooltip: any;
  // scrollEvent = (event: any): void => {
  //   this.show = false;
  //   // if (this.tooltip) this.tooltip.hide();

  //   // this.show = !this.show;
  //   if (!this.show && this.tooltip) {
  //     setTimeout(() => {
  //       this.tooltip._results.forEach(item => item.hide());
  //     }, 5)
  //   }
  // };

  currAssessmentIndex: any = undefined;
  is_completed: boolean = false;

  selectedSectionIndex: any;
  selectedQuestionIndex: any;
  selectedAnswerIndex: any;

  isPreview: boolean = false;

  assessmentTypes: any = undefined;
  assessmentSet: any = [];
  assessmentRunData: any = undefined;

  patientAssessmentId: any = null;
  runId: any = null;

  mandatoryAttended: boolean = false;

  progress: boolean = false;

  indexMaintainer: any = {}

  stepperSectionIndex: any = 0;
  nextSection: any = undefined;
  completedSection: any = []
  unanswered: any = {};
  continuityUnAnswered: any = {};

  isTimeUp: boolean = false;
  expiry_time: any = undefined;
  show;
  viewLanguage: any = 'english';

  isQuestionProcessing: boolean = false;
  sectionData: Array<any> = [];
  allSections: Array<any> = [];
  allSectionIds: Array<any> = [];
  updatePatientAnswerCount = 0;
  singleInputAnswerUpdateCount = 0;
  completeAssessmentCount = 0;
  moveToNextAssessmentCount = 0;

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  constructor(private patientAssessmentService: PatientAssessmentService,
    private snackbar: SnackbarCongiration,
    private deviceService: DeviceDetectorService,
    private dialog: MatDialog,
    private router: Router,
    public dialogRef: MatDialogRef<PatientAssessmentViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    // window.addEventListener('scroll', this.scrollEvent, true);
    if (this.data && this.data['screenType'] == 'preview') {
      this.isPreview = true;
      this.assessmentTypes = this.data['assessmentTypes'];
      let questionTypes = {}
      this.assessmentTypes.forEach(element => {
        questionTypes[(element.id).toString()] = element.value;
      });
      this.assessmentTypes = questionTypes;
      this.assessmentSet = this.data['assessment'][0];
    }
  }

  // ngOnInit() {

  // }

  ngOnChanges() {
    if (this.data && !('screenType' in this.data)) {
      this.assessmentTypes = this.assessmentData['assessment_type'];
      this.assessmentSet = this.assessmentData['all_run'];
      this.assessmentRunData = this.assessmentData['assessment'];
      this.viewLanguage = this.assessmentData['language'];
      this.patientAssessmentId = this.assessmentData['patientAssessmentId'];
      this.runId = this.assessmentData['assessment']['run_id'];
      this.currAssessmentIndex = this.getCurrAssessmentIndex(this.runId)
    }

    this.initializeSections();
  }

initializeSections() {
    this.allSections = [];
    this.allSectionIds = [];
    if (this.isPreview) {
      this.sectionData = this.assessmentSet['section'];
    }
    else {
      this.sectionData = this.assessmentRunData['section'];
    }

    this.sectionData.forEach(element => {
      this.allSectionIds.push(element['id']);
      this.allSections.push(element);
      element['continuity'].forEach(continuityElem => {
        this.allSectionIds.push(continuityElem['id']);
        this.allSections.push(continuityElem);
      });
    });
  }

  getSectionQuestionsOrContinuity(sectionIndex, questionIndex, continuitySectionIndex?: number) {
    let questionArr = [];
    if (typeof continuitySectionIndex != 'undefined') {
      questionArr = this.assessmentRunData['section'][sectionIndex]['continuity'][continuitySectionIndex]['questions'];
    }
    else {
      questionArr = this.assessmentRunData['section'][sectionIndex]['questions'];
    }
    return questionArr;
  }

  selectedAnswer(assessmentIndex, sectionIndex, questionIndex, answerIndex, continuitySectionIndex?: number, isMultiCheckBox?: boolean, isCheckBox?: boolean, event?: any) {
    this.selectedAnswerIndex = answerIndex;
    this.selectedQuestionIndex = questionIndex;
    let questionArr = this.getSectionQuestionsOrContinuity(sectionIndex, questionIndex, continuitySectionIndex);  
    let question = questionArr[questionIndex];

    this.removeUnAnsweredQuestion(sectionIndex, questionIndex, continuitySectionIndex);  

    let config = {
      assessmentIndex: assessmentIndex,
      sectionIndex: sectionIndex,
      questionIndex: questionIndex,
      answerIndex: answerIndex
    };

    // other than multi checkbox
    if (!isMultiCheckBox) {
      if (isCheckBox) {
        if (event.checked) {
          question['isAnswered'] = { 'option_value': question['answers'][answerIndex]['id'] };
        }
        else {
          delete question['isAnswered'];
        }
      }
      else {
        question['isAnswered'] = { 'option_value': question['answers'][answerIndex]['id'] };
      }
    }
    // multi checkbox
    else if (isMultiCheckBox) {
      if (event.checked) {
        if (!question.hasOwnProperty('isAnswered')) {
          question['isAnswered'] = { 'option_values': [] };
          question['isAnswered']['option_values'].push(question['answers'][answerIndex]['id']);
        }
        else if (question.hasOwnProperty('isAnswered')) {
          question['isAnswered']['option_values'].push(question['answers'][answerIndex]['id']);
        }
      }
      else {
        let option_values = question['isAnswered']['option_values']
        question['isAnswered']['option_values'].splice(
          option_values.indexOf(question['answers'][answerIndex]['id']), 1
        )
        if (option_values.length == 0) {
          delete question['isAnswered'];
        }
      }
    }
    this.updatePatientAnswer(questionArr, config);
  }

  updatePatientAnswer(questionArr, config) {
    let currQuestion = questionArr[config['questionIndex']];
    let data = {
      'questionId': currQuestion.id,
      'answerId': questionArr[config['questionIndex']].answers[config['answerIndex']].id,
      'run_id': this.runId,
      'token': this.assessment_take_payload['token'],
      'patientAssessmentId': this.patientAssessmentId,
      'runIds': this.assessmentSet
    }
    if (currQuestion.hasOwnProperty('child_answered_id')) {
      data['child'] = currQuestion['child_answered_id'];
    }
    this.isQuestionProcessing = true;
    this.patientAssessmentService.updatePatientAssessmentAnswer(data).subscribe(response => {
      this.manageQuestionDependency(questionArr, response['data']);
      if (currQuestion.is_dependent) {
        // check
        questionArr[currQuestion['parent_question']['questionIndex']]['child_answered_id'] = response['id'];
      }
      this.isQuestionProcessing = false;
    },
    error => {
      let data = [questionArr, config]
        this.navigateToExpired(error, "updatePatientAnswer", data);
      this.isQuestionProcessing = false;
    })
  }

  singleInputKepUp(event, sectionIndex, questionIndex, continuitySectionIndex) {
    let questionArr = this.getSectionQuestionsOrContinuity(sectionIndex, questionIndex, continuitySectionIndex);
    questionArr[questionIndex]['isAnswered'] = { 'isSingleInput': true, 'option_value': event.target.value };
    if (event.target.value != "") {
      // let unansweredQuesArr = this.unanswered[sectionIndex];
      // if (unansweredQuesArr && unansweredQuesArr.includes(questionIndex)) {
      //   unansweredQuesArr.splice(unansweredQuesArr.indexOf(questionIndex), 1);
      // }
      this.removeUnAnsweredQuestion(sectionIndex, questionIndex, continuitySectionIndex);
    }
    else if (questionArr[questionIndex]['mandatory']) {
      this.addUnAnsweredQuestion(sectionIndex, questionIndex);
    }
  }

  singleInputAnswerUpdate(event, assessmentIndex, sectionIndex, questionIndex, continuitySectionIndex) {
    let questionArr = this.getSectionQuestionsOrContinuity(sectionIndex, questionIndex, continuitySectionIndex);
    let data = {
      'questionId': questionArr[questionIndex].id,
      'answer': event.target.value,
      'isSingleInput': true,
      'run_id': this.runId,
      'token': this.assessment_take_payload['token']
    }
    this.isQuestionProcessing = true;
    this.patientAssessmentService.updatePatientAssessmentAnswer(data).subscribe(response => {
      if (response['status']) {
        questionArr[questionIndex]['isAnswered'] = { 'isSingleInput': true, 'option_value': event.target.value };
        this.isQuestionProcessing = false;
      }
    },
    error => {
      let data = [event, assessmentIndex, sectionIndex, questionIndex, continuitySectionIndex]
        this.navigateToExpired(error, "singleInputAnswerUpdate", data);
      this.isQuestionProcessing = false;
    })
  }

  checkSectionMandatoryQuestions() {
    this.unanswered = {};
    this.continuityUnAnswered = {};
    let sectionObj = this.assessmentRunData['section'][this.stepperSectionIndex];
    // checking normal questions inside section
    this.checkQuestionMandatory(this.stepperSectionIndex, sectionObj['questions']);

    // checking continuity sections
    if (sectionObj['continuity'].length > 0) {
      for (let [continuityIndex, contSectionObj] of sectionObj['continuity'].entries()) {
        this.checkQuestionMandatory(this.stepperSectionIndex, contSectionObj['questions'], continuityIndex);
      }
    }
    return Object.keys(this.unanswered).length == 0 && Object.keys(this.continuityUnAnswered).length == 0 ;
  }

  checkAssessmentTab() {
    this.unanswered = {};
    this.continuityUnAnswered = {};
    let sectionArr = this.assessmentRunData['section'];
    for (let [sectionIndex, sectionObj] of sectionArr.entries()) {
      this.checkQuestionMandatory(sectionIndex, sectionObj['questions']);
      if (sectionObj['continuity'].length > 0) {
        for (let [continuityIndex, contSectionObj] of sectionObj['continuity'].entries()) {
          this.checkQuestionMandatory(sectionIndex, contSectionObj['questions'], continuityIndex);
        }
      }
    }
    return Object.keys(this.unanswered).length == 0 && Object.keys(this.continuityUnAnswered).length == 0 ;
  }

  checkQuestionMandatory(sectionIndex, questionArr, continuityIndex?:number) {
    for (let [questionIndex, questionObj] of questionArr.entries()) {
      if (questionObj.mandatory) {
        if (!questionObj.hasOwnProperty('isAnswered')) {
          this.addUnAnsweredQuestion(sectionIndex, questionIndex, continuityIndex);
        }
        else if ('isSingleInput' in questionObj['isAnswered'] && questionObj['isAnswered']['option_value'] == "") {
          this.addUnAnsweredQuestion(sectionIndex, questionIndex, continuityIndex);
        }
      }
    }
  }

  addUnAnsweredQuestion(sectionIndex, questionIndex, continuityIndex?:number) {
    if (typeof continuityIndex != 'undefined') {
      let key = sectionIndex + '#' + continuityIndex;
      if (!(key in this.continuityUnAnswered)) {
        this.continuityUnAnswered[key] = [];
      }
      this.continuityUnAnswered[key].push(questionIndex);
    }
    else {
      if (!(sectionIndex in this.unanswered)) {
        this.unanswered[sectionIndex] = [];
      }
      this.unanswered[sectionIndex].push(questionIndex);
    }
  }

  removeUnAnsweredQuestion(sectionIndex, questionIndex, continuitySectionIndex?: number) {
    let unansweredArr = undefined;
    if (typeof continuitySectionIndex != 'undefined') {
      let key = sectionIndex + '#' + continuitySectionIndex;
      unansweredArr = this.continuityUnAnswered[key];
    }
    else {
      unansweredArr = this.unanswered[sectionIndex];
    }

    // if question is answered removing from unanswered list
    if (unansweredArr && unansweredArr.includes(questionIndex)) {
      unansweredArr.splice(unansweredArr.indexOf(questionIndex), 1);
    }
  }

  updateCurrentAssessmentIndex() {
    if (this.currAssessmentIndex != this.assessmentSet.length - 1) {
      if (this.checkAssessmentTab()) {
        this.currAssessmentIndex = this.currAssessmentIndex + 1;
        this.runId = this.assessmentSet[this.currAssessmentIndex].run_id;
      }
      else {
        let msg = 'mandatory'
        if (this.viewLanguage == 'spanish') {
          msg = 'mandatory_sp'
        }
        this.snackbar.triggerSnackBar({
          message: statusmessage['patientassesmentake'][msg],
          type: 'warning'
        });
      }
    }
  }


  completeAssessment() {
    if (this.checkAssessmentTab()) {
      this.progress = true;
      let payload = {
        'patientAssessmentId': this.patientAssessmentId,
        'runIds': this.assessmentSet,
        'token': this.assessment_take_payload['token']
      }
      let time_offset = new Date().getTimezoneOffset();
       payload['time_offset'] = time_offset;
      this.patientAssessmentService.submitPatientAssessment(payload).subscribe(response => {
        // this.is_completed = true;
        this.progress = false;
        if (response.hasOwnProperty('token')) {
          let dialogRef = this.openConfirmer(response['assessment_count'] + ' more assessment is scheduled for you today. Do you like to take it?', 'Proceed Now')
          dialogRef.afterClosed().subscribe(
            value => {
              if (value == 'yes') {
                this.router.navigate(['/survey/device'], { queryParams: { token: response['token'], liability: 0 } });
              }
              else {
                this.manageAssessmentPostRouting(response);
              }
            }
          )
        }
        else {
          this.manageAssessmentPostRouting(response)
        }
        // setTimeout(() => {
        //   var ele = document.getElementById("academy-courses");
        //   ele.scrollIntoView({behavior: "smooth"});
        // }, 500);
        // this.emitAssessmentComplete.emit(this.is_completed);
      },
      error => {
        this.navigateToExpired(error, "completeAssessment");
      })
    }
    else {
      let msg = 'mandatory'
      if (this.viewLanguage == 'spanish') {
        msg = 'mandatory_sp'
      }
      this.snackbar.triggerSnackBar({
        message: statusmessage['patientassesmentake'][msg],
        type: 'warning'
      });
    }
  }

  manageQuestionDependency(questions, data) {
    if ('question_dependency' in data) {
      if (data['question_dependency']['add_ids'].length > 0) {
        data['question_dependency']['qd_data'].forEach((element, index) => {
          if (data['question_dependency']['add_ids'].includes(element.id)) {
            questions.push(element);
          }
        });
      }

      if (data['question_dependency']['remove_ids'].length > 0) {
        data['question_dependency']['remove_ids'].forEach(remove_id => {
          questions.forEach((element, index) => {
            if (remove_id == element.id) {
              questions.splice(index, 1);
            }
          });
        });
      }
    }

    if ('answer_dependency' in data) {
      if (data['answer_dependency']['add_ids'].length > 0) {
        data['answer_dependency']['ad_data'].forEach((element, index) => {
          if (data['answer_dependency']['add_ids'].includes(element.id)) {
            questions.push(element);
          }
        });
      }
    }

    if (data['answer_dependency']['remove_ids'].length > 0) {
      data['answer_dependency']['remove_ids'].forEach((remove_id) => {

        if (remove_id in data['answer_dependency']['multi_answer_set']) {
          data['answer_dependency']['multi_answer_set'][remove_id].forEach(answer_set => {
            questions.forEach((element, index) => {
              if (element['answers'].length > 0) {
                if (remove_id == element.id && element['answers'][0]['answer_set'] == answer_set) {
                  questions.splice(index, 1);
                }
              }
            });
          });

        }
        else {
          questions.forEach((element, index) => {
            if (remove_id == element.id) {
              questions.splice(index, 1)
            }
          });
        }
      });
    }
    questions.sort((a,b) => {return a.question_order - b.question_order});
  }

  checkAnswerChecked(question, answer, isMultiCheckBox?: boolean) {
    if ('isAnswered' in question) {
      if (!isMultiCheckBox && question.isAnswered['option_value'] == answer['id']) {
        return true;
      }
      else if (isMultiCheckBox && question.isAnswered['option_values'].includes(answer['id'])) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }

  }

  checkAnswerHighlighted(question, answerIndex, answer, isMultiCheckBox?: boolean) {
    if (this.checkAnswerChecked(question, answer, isMultiCheckBox)) {
      return 'highlighted-answer'
    }
    return ''
  }

  getUnAnsweredHighlights(sectionIndex, questionIndex, continuitySectionIndex?: number) {
    if (typeof continuitySectionIndex != 'undefined') {
      let key = sectionIndex + '#' + continuitySectionIndex;
      if (this.continuityUnAnswered[key] && this.continuityUnAnswered[key].includes(questionIndex)) {
        return 'unanswered'
      }
    }
    else if (this.unanswered[sectionIndex] && this.unanswered[sectionIndex].includes(questionIndex)) {
      return 'unanswered'
    }
    return ''
  }

  stepperChange(event) {
    this.stepperSectionIndex = event.selectedIndex;
  }

  // previewStepperChange() {
  //   let elem = document.getElementById('preview-stepper');
  //   console.log(elem)
  //   elem.scrollIntoView();
  // }

  moveToNextSextion(stepper: MatHorizontalStepper) {
    this.stepperSectionIndex = stepper['_selectedIndex'];
    if (this.checkSectionMandatoryQuestions()) {
      stepper.next()
    }
    else {
      this.unanswered
      let msg = 'mandatory';
      if (this.viewLanguage == 'spanish') {
        msg = 'mandatory_sp'
      }
      this.snackbar.triggerSnackBar({
        message: statusmessage['patientassesmentake'][msg],
        type: 'warning'
      });
    }
  }

  moveToNext() {
    if (this.checkAssessmentTab()) {
      this.moveToNextAssessment();
    }
    else {
      let msg = 'mandatory';
      if (this.viewLanguage == 'spanish') {
        msg = 'mandatory_sp'
      }
      this.snackbar.triggerSnackBar({
        message: statusmessage['patientassesmentake'][msg],
        type: 'warning'
      });
    }
  }

  moveToNextAssessment() {
    this.progress = true;
    let payload = {
      'patientAssessmentId': this.patientAssessmentId,
      'token': this.assessment_take_payload['token'],
      'runIds': this.assessmentSet,
    }
    let time_offset = new Date().getTimezoneOffset();
    payload['time_offset'] = time_offset;
    this.patientAssessmentService.updatePatientAssessmentRunStatus(payload).subscribe(data => {
      if (data['status']) {
        this.progress = false;
        if ('assessment_data' in data) {
          // console.log(data)
          this.assessmentRunData = data['assessment_data']['assessment'];
          this.runId = this.assessmentRunData['run_id'];
          this.currAssessmentIndex = this.getCurrAssessmentIndex(this.assessmentRunData['run_id']);
          this.stepperSectionIndex = 0;
          this.initializeSections();
          // setTimeout(() => {
          //   var ele = document.getElementById("scroll-container");
          //   // console.log(ele)
          //   ele.scrollIntoView();
          // }, 500);
        }
        else {
          this.is_completed = true;
          let msg = 'completed';
          if (this.viewLanguage == 'spanish') {
            msg = 'completed_sp'
          }
         // this.snackbar.triggerSnackBar({
           // message: statusmessage['patientassesmentake'][msg],
            //type: 'success'
          //});
          this.router.navigate(['/survey/complete-assessment']);
          this.emitAssessmentComplete.emit(this.is_completed);
        }
      }
    },
    error => {
      this.navigateToExpired(error, "moveToNextAssessment");
    })
  }

  getCurrAssessmentIndex(runId) {
    for (let [index, runIdElement] of this.assessmentSet.entries()) {
      if (runIdElement['id'] == runId) {
        return index;
      }
    }
  }

  getQuestionNumber(sectionId, questionIndex) {
    let endIndex = this.allSectionIds.indexOf(sectionId);
    if (endIndex != -1) {
      let sectionArr = [...this.allSections.slice(0, endIndex + 1)];
      let questionLength = 0;
      sectionArr.forEach((elem, index) => {
        if (index > 0) {
          if (!elem['show_continuity']) {
            questionLength = 0;
            questionLength = questionLength + questionIndex + 1;
          }
          else {
            questionLength = questionLength + sectionArr[index - 1]['questions'].length;
          }
        }
        else {
          questionLength = questionIndex + 1;
        }
      });
      return questionLength;
    }
    return 0;
  }

  timeUp(event) {
    let msg = 'timeup';
    if (this.viewLanguage == 'spanish') {
      msg = 'timeup_sp'
    }
    this.snackbar.triggerSnackBar({
      message: statusmessage['patientassesmentake'][msg],
      type: 'warning'
    });
    this.moveToNextAssessment();
  }

  closeModal() {
    this.dialogRef.close()
  }

  scrollHandle() {
    this.tooltip.hide();
  }

  manageAssessmentPostRouting(response) {
    var alert_url = "";
    if (this.assessment_take_payload['take_type'] == 'device') {
      let msg = 'confirmationmessage';
      if (this.viewLanguage == 'spanish') {
        msg = 'confirmationmessage_sp'
      }
      //this.snackbar.triggerSnackBar({
        //message: statusmessage['patientassesmentake'][msg],
        //type: 'success'
      //});
      // this.router.navigate(['/take-assessment/device-take']);
    }
    if ('language' in response) {
      let token = window.btoa(JSON.stringify({ 'language': response['language'] }));
      this.router.navigate(['/survey/complete-assessment'], { queryParams: { 'token': token } });
      if(Object.keys(response['alert_url']).length != 0){
          const isMobile = this.deviceService.isMobile();
          const isTablet = this.deviceService.isTablet();
          const isDesktopDevice = this.deviceService.isDesktop();
          console.log(isMobile,isTablet,isDesktopDevice)
          
          if (response['alert_url']!= null){
            if(!response['alert_url'].includes("http"))
              alert_url = "https://"+response['alert_url']
            else
              alert_url = response['alert_url'];
            window.location.replace(alert_url);
          }

          // if (response['alert_url']["Both"] != null){
          //   if(!response['alert_url']["Both"].includes("http"))
          //     alert_url = "https://"+response['alert_url']["Both"];
          //   else
          //     alert_url = response['alert_url']["Both"];
          //   window.location.replace(alert_url);
          // }
          // else if (isMobile && (response['alert_url']["Mobile"] != null)){
          //   if(!response['alert_url']["Mobile"].includes("http"))
          //     alert_url = "https://"+response['alert_url']["Mobile"]
          //   else
          //     alert_url = response['alert_url']["Mobile"];
          //   window.location.replace(alert_url);
          // }
          // else if (isDesktopDevice || isTablet && (response['alert_url']["Desktop/Tablet"] != null)){
          //   if(!response['alert_url']["Desktop/Tablet"].includes("http"))
          //     alert_url = "https://"+response['alert_url']["Desktop/Tablet"]
          //   else
          //   alert_url = response['alert_url']["Desktop/Tablet"];
          //   window.location.replace(alert_url);
          //   // window.open("https://www.google.com", "_blank");
          // }
            
        }
    }
    else {
      this.router.navigate(['/survey/complete-assessment']);
      if(Object.keys(response['alert_url']).length != 0){
          const isMobile = this.deviceService.isMobile();
          const isTablet = this.deviceService.isTablet();
          const isDesktopDevice = this.deviceService.isDesktop();
          console.log(isMobile,isTablet,isDesktopDevice)

          if (response['alert_url'] != null){
            if(!response['alert_url'].includes("http"))
              alert_url = "https://"+response['alert_url']
            else
              alert_url = response['alert_url'];
            window.location.replace(alert_url);
          }
          
          // if (response['alert_url']["Both"] != null){
          //   if(!response['alert_url']["Both"].includes("http"))
          //     alert_url = "https://"+response['alert_url']["Both"];
          //   else
          //     alert_url = response['alert_url']["Both"];
          //   window.location.replace(alert_url);
          // }
          // else if (isMobile && (response['alert_url']["Mobile"] != null)){
          //   if(!response['alert_url']["Mobile"].includes("http"))
          //     alert_url = "https://"+response['alert_url']["Mobile"]
          //   else
          //     alert_url = response['alert_url']["Mobile"];
          //   window.location.replace(alert_url);
          // }
          // else if (isDesktopDevice || isTablet && (response['alert_url']["Desktop/Tablet"] != null)){
          //   if(!response['alert_url']["Desktop/Tablet"].includes("http"))
          //     alert_url = "https://"+response['alert_url']["Desktop/Tablet"]
          //   else
          //   alert_url = response['alert_url']["Desktop/Tablet"];
          //   window.location.replace(alert_url);
          //   // window.open("https://www.google.com", "_blank");
          // }
         
        } 
    }  
  }

  navigateToExpired(error, method, params = []) {
    console.log(error, typeof(error))
    if (typeof (error) == 'object' && 'clinic_name' in error) {
      let payload = { "clinic_name": error['clinic_name'] };
      if ('completed' in error && error['completed']) {
        payload['completed'] = true;
      }
      if ('language' in error) {
        payload['language'] = error['language'];
      }
      let invalid_token = window.btoa(JSON.stringify(payload));
      this.router.navigate(['/invalid'], { queryParams: { 'token': invalid_token } })
    }
    else if (typeof (error) == 'string' && error.toLowerCase().includes("timeout has occurred")) {
      let count = method + 'Count';
      // this[count] += 1;
      if (this[count] < 3) {
        let data = {
          image: true,
          message: "We’re having an issue with your last response,<br> please try again",
          button: "Retry",
          is_tab_close: false
        };
        this.retryDialog(data, method, params);
      }
      else {
        let data = {
          image: false,
          message: "Sorry, we are still having issues with your connection.<br> Please restart your assessment(s)",
          is_tab_close: true
        };
        this.retryDialog(data, method, params);
      }

      // this.router.navigate(['/invalid'])
    }
    else if (typeof (error) == 'object' && (error['total'] == 0 || error['status'] == 0)) {
      let data = {
        image: false,
        message: "Sorry, we are having issues with your connection.<br> Please restart your assessment(s)",
        is_tab_close: true
      };
      this.retryDialog(data, method, params);

      // this.router.navigate(['/invalid'])
    }
    else {
      let data = {
        image: false,
        message: "Sorry, We’re having an issue with your last response, <br> please try again",
        button: "Retry",
        is_tab_close: false
      };
      this.retryDialog(data, method, params);
    }
  }

  retryDialog(data, method, params) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.position = { top: '70px' };
    dialogConfig.width = '600px';
    dialogConfig.disableClose = true;
    // dialogConfig.restoreFocus = false;
    dialogConfig.data = {
      image: data['image'],
      message: data['message'],
      button: data['button'],
      is_tab_close: data['is_tab_close']
    }
    let dialogRef = this.dialog.open(ReloadComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      value => {
        if (value == 'yes') {
          this.reloadMessage(method, params);
        }
      }
    );
  }

  reloadMessage(method, params) {
    let count = method + 'Count';
    this[count] += 1;
    if (this[count] <= 3) {
      // this.verifyAssessment(this.assessmentTimeOutData);
      this[method](...params);
    }
    else {
      let data = {
        image: false,
        message: "Sorry, we are still having issues with your connection.<br> Please restart your assessment(s)",
        is_tab_close: true
      };
      this.retryDialog(data, method, params);
    }
  }

  openConfirmer(message, yes_button) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.position = { top: '70px' };
    dialogConfig.width = '600px';
    dialogConfig.disableClose = true;
    dialogConfig.restoreFocus = false;
    dialogConfig.data = {
      message: message,
      item: yes_button
    }
    let dialogRef = this.dialog.open(ConfimerComponent, dialogConfig);
    return dialogRef;
  }

}
