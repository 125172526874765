import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
// import { FuseConfigService } from 'app/shared/services/config.service';
import { map } from 'rxjs/operators';
import { LocationStrategy } from '@angular/common';
import * as moment from 'moment';
import { PatientAssessmentService } from '../support/services/patient-assessment.service';
import { SnackbarCongiration } from '../shared/components/snack-bar/SnackbarConfiguration';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ReloadComponent } from '../shared/components/dialog-box/reload/reload.component';

@Component({
  selector: 'app-patient-assessment-link',
  templateUrl: './patient-assessment-link.component.html',
  styleUrls: ['./patient-assessment-link.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PatientAssessmentLinkComponent implements OnInit {

  assessment_token: any = undefined;
  screenType: any = '';
  auth_data: any = undefined;

  answers: any
  selectedIndex: any;
  showAssessment: any = false;

  assessmentData: any = undefined;

  currAssessmentIndex: any = 0;

  is_completed: boolean = false;

  assessment_code: FormControl = new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$')]);
  assessment_code_value: any = null;

  assessment_take_payload = {

  }

  take_type: any;

  state$: any = undefined
  language: any = 'english';

  languages_enabled: Array<string> = [];

  tokenExpiry: any = undefined;
  retryCount = 0;
  assessmentTimeOutData: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    // private _fuseConfigService: FuseConfigService,
    private patientAssessmentService: PatientAssessmentService,
    private snackbar: SnackbarCongiration,
    private location: LocationStrategy,
    private dialog: MatDialog,
  ) {

    // disabling back button
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    })


    this.assessmentDetails();

    // this._fuseConfigService.config = {
    //   layout: {
    //     navbar: {
    //       hidden: true
    //     },
    //     toolbar: {
    //       hidden: true
    //     },
    //     footer: {
    //       hidden: true
    //     },
    //     sidepanel: {
    //       hidden: true
    //     }
    //   }
    // };
  }

  ngOnInit() {

  }

  onAuthVerify(event) {
    let data = {};
    data = { ...data, ...event }
    data['token'] = this.assessment_token;
    data['take_type'] = this.take_type;
    data['language'] = this.language;
    this.verifyAssessment(data);
  }

  onAssessmentComplete(event) {
    this.is_completed = event;
  }

  assessmentDetails() {
    if (this.router.url.indexOf('?') > 0) {
      // var arr = this.router.url.split('?')
      var arr = window.location.href.split('?');
      if (arr[0].includes('survey')) {
        this.route.queryParams.subscribe(params => {
          // console.log(params);
          if (Object.keys(params).indexOf('token') < 0) {
            // this.screenType = 'code';
            // this.assessment_take_payload['isTokenBased'] = false;
            this.router.navigate(['/invalid'])
          }
          else {
            this.assessment_token = params['token'];
            // this.assessment_take_payload['isTokenBased'] = true;
            this.assessment_take_payload['token'] = this.assessment_token;
            arr[0].includes('device') ? this.take_type = 'device' : this.take_type = 'remote';
            this.assessment_take_payload['take_type'] = this.take_type;
            let verification_payload = new Object();
            verification_payload['token'] = this.assessment_token;
            verification_payload['take_type'] = this.take_type;
            verification_payload['language'] = this.language;
            if ('liability' in params)  {
              verification_payload['liability_not_needed'] = true;
            }
            if ('formfactor' in params) {
              verification_payload['mobile'] = true;
            }
            let time_offset = new Date().getTimezoneOffset();
            verification_payload['time_offset'] = time_offset;
            this.verifyAssessment(verification_payload);
          }
        });
      }
    }
    else {
      this.router.navigate(['/invalid'])
      // this.screenType = 'code';
      // this.assessment_take_payload['isTokenBased'] = false;
    }
  }

  verifyAssessment(data) {
    this.assessmentTimeOutData = data;
    this.patientAssessmentService.verifyAssessmentTakeToken(data).subscribe(response => {
      if (response['status']) { 
        this.screenType = response['type'];
        if (this.screenType == 'auth') {
          this.auth_data = response['auth_data'];
          if ('mobile' in data && data['mobile']) {
            this.assessment_token = response['auth_data']['mobile_token'];
            this.assessment_take_payload['token'] = this.assessment_token;
          }
        }
        else if (this.screenType == 'assessment') {
          if ('mobile' in data && data['mobile']) {
            if ('mobile_token' in response) {
              this.assessment_token = response['mobile_token'];
            }
            this.assessment_take_payload['token'] = this.assessment_token;
          }
          this.assessmentData = response['assessment_data'];
          this.language = this.assessmentData['language'];
          this.languages_enabled = this.assessmentData['languages_enabled'];
          this.tokenExpiry = moment(this.assessmentData['token_expiry']).format('LL hh:mm a');
        }
      }
      else {
        this.snackbar.triggerSnackBar({
          message: response['message'],
          type: 'error'
        });
      }
    },
    error => {
      console.log(error, typeof(error))
      if (typeof (error) == 'object' && 'clinic_name' in error) {
        let payload = { "clinic_name": error['clinic_name'] };
        if ('completed' in error && error['completed']) {
          payload['completed'] = true;
        }
        if ('language' in error) {
          payload['language'] = error['language'];
        }
        let invalid_token = window.btoa(JSON.stringify(payload));
        this.router.navigate(['/invalid'], { queryParams: { 'token': invalid_token } })
      }
      else if (typeof (error) == 'string' && error.toLowerCase().includes("timeout has occurred")) {
        if (this.retryCount < 3) {
          let data = {
            image: true,
            message: "We’re having an issue with your last response, <br> please try again",
            button: "Retry",
            is_tab_close: false
          };
          this.retryDialog(data);
        }
        else {
          let data = {
            image: false,
            message: "Sorry, we are still having issues with your connection.<br> Please restart your assessment(s)",
            is_tab_close: true,
          };
          this.retryDialog(data);
        }
        // this.router.navigate(['/invalid'])
      }
      else if (typeof (error) == 'object' && (error['total'] == 0 || error['status'] == 0)) {
        let data = {
          image: false,
          message: "Sorry, we are having issues with your connection. <br> Please restart your assessment(s)",
          is_tab_close: true,
        };
        this.retryDialog(data);

        // this.router.navigate(['/invalid'])
      }
      else {
        let data = {
          image: false,
          message: "Sorry, We’re having an issue with your last response, <br> please try again",
          button: "Retry",
          is_tab_close: false
        };
        this.retryDialog(data);
      }
    });
  }

  retryDialog(data) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.position = { top: '70px' };
    dialogConfig.width = '450px';
    dialogConfig.disableClose = true;
    // dialogConfig.restoreFocus = false;
    dialogConfig.data = {
      image: data['image'],
      message: data['message'],
      button: data['button'],
      is_tab_close: data['is_tab_close'],
    }
    let dialogRef = this.dialog.open(ReloadComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      value => {
        if (value == 'yes') {
          this.reloadMessage();
        }
      }
    );
  }

  reloadMessage() {
    this.retryCount += 1;
    if (this.retryCount <= 3) {
      this.verifyAssessment(this.assessmentTimeOutData);
    }
    else {
      let data = {
        image: false,
        message: "Sorry, we are still having issues with your connection.<br> Please restart your assessment(s)",
        is_tab_close: true
      };
      this.retryDialog(data);
    }


  }


  translateSpanish() {
    this.language = 'spanish'
    this.assessmentDetails();
  }

  translateEnglish() {
    this.language = 'english'
    this.assessmentDetails();
  }
}
