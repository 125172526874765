import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExpiredComponent } from './no-auth/expired/expired.component';
import { PermissionDeniedComponent } from './no-auth/permission-denied/permission-denied.component';

const routes: Routes = [
  {
    path: 'survey',
    loadChildren: () => import('./patient-assessment-link/patient-assessment-link.module').then(m => m.PatientsAssessmentLinkModule),
  },
  { path: 'invalid', component: ExpiredComponent },
  { path: 'permission-denied', component: PermissionDeniedComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
