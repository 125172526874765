import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-complete-assessment',
  templateUrl: './complete-assessment.component.html',
  styleUrls: ['./complete-assessment.component.scss']
})
export class CompleteAssessmentComponent implements OnInit {

  decoded_json: any = null;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {

    if (this.router.url.indexOf('?') > 0) {
      this.route.queryParams.subscribe(params => {
        if ('token' in params) {
          try {
            this.decoded_json = JSON.parse(window.atob(params['token']));
            // console.log(this.decoded_json);
          } catch (e) {
            // console.log('invalid json in token.')
          }
        }
      })
    }
  }

  ngOnInit() {
    // window.postMessage("assessment completed", window.location.href);
     if((<any>window).ReactNativeWebView){
       setTimeout(() => {
   (<any>window).ReactNativeWebView.postMessage("assessment_completed");
}, 5000);
    }
  }
  

}
