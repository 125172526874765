// angular imports
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

// angular material imports
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import {
    MatTableModule, MatPaginatorModule, MatChipsModule, MatAutocompleteModule, MatSelectModule,
    MatRadioModule, MatTabsModule, MatListModule, MatTooltipModule, MatButtonToggleModule, MatCardModule,
    MatDatepickerModule, MatGridListModule, MatSortModule, MatToolbarModule, MatBadgeModule, MatProgressSpinnerModule, MatSnackBarModule, MatExpansionModule, MatSlideToggleModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter
} from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';


// project imports
// modules
import { DialogModule } from './components/dialog-box/dialog.module';

// components
import { SnackBarModule } from './components/snack-bar/snack-bar.module';

import { MAT_MOMENT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
// import { QuicklinkModule } from 'ngx-quicklink';

const ALL_MODULES = [
    // angular modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,

    // angular material modules
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatStepperModule,
    MatTableModule,
    MatPaginatorModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatRadioModule,
    MatChipsModule,
    MatTabsModule,
    MatListModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDatepickerModule,
    MatGridListModule,
    MatSortModule,
    MatToolbarModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatToolbarModule,
    MatDialogModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatSlideToggleModule,

    // project modules
    DialogModule,
    SnackBarModule,
];

@NgModule({
    imports: ALL_MODULES,
    exports: [
        ALL_MODULES,
    ],
    declarations: [],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        // { provide: MAT_DATE_FORMATS, useValue: APP_FORMAT },
        // { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
        // { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    ],
    entryComponents: [
    ]
})
export class SharedModule { }
