import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-expired',
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.scss']
})
export class ExpiredComponent implements OnInit {

  invalid: any = false;
  decoded_json: any = null;
  errorMessage: any = 'Invalid Link'

  constructor(
    private router: Router,
    private route: ActivatedRoute) {
    if (this.router.url.indexOf('?') > 0) {
      this.route.queryParams.subscribe(params => {
        if ('token' in params) {
          try {
            this.decoded_json = JSON.parse(window.atob(params['token']));
            // console.log(this.decoded_json);
          } catch (e) {
            this.invalid = true;
            // console.log('invalid json in token.')
          }
        }
        else if ('context' in params) {
          if (params['context'] == 'password') {
            this.errorMessage = 'The link you are trying to access has expired. Please contact Admin to send you a password reset link or click on Forgot Password in MHT portal login screen.';
          }
        }
        else {
          this.invalid = true;
        }
      })
    }
    else {
      this.invalid = true;
    }
  }

  ngOnInit() {

  }
  close() {
    window.close();
  }
}
