import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { PatientAssessmentService } from 'src/app/support/services/patient-assessment.service';

@Component({
  selector: 'patient-assessment-auth',
  templateUrl: './patient-assessment-auth.component.html',
  styleUrls: ['./patient-assessment-auth.component.scss']
})
export class PatientAssessmentAuthComponent implements OnInit {

  @Input() auth_data: any;
  @Input() assessment_code: any;
  @Output() emitAuthResponse = new EventEmitter<any>();


  liability_data: any = undefined;
  patient_data: any = null;
  language: string = null;
  inProgress: boolean = false;

  constructor(private patientAssessmentService: PatientAssessmentService,
    private router: Router) {

  }

  ngOnInit() {
    this.language = this.auth_data['language'];
 
  }

  verifyStartAssessment() {
    this.inProgress = true;
    let data = {}
    data['liability_accepted'] = true;
    let time_offset = new Date().getTimezoneOffset();
    data['time_offset'] = time_offset;
    this.emitAuthResponse.emit(data);
  }


  
}
