import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'permission-denied',
  templateUrl: './permission-denied.component.html',
  styleUrls: ['./permission-denied.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PermissionDeniedComponent implements OnInit {
  page: string = 'login';

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(
      private router: Router
  )
  {
      // Configure the layout
  }

  ngOnInit() {
  }

}
