import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { DialogComponent } from './dialog.component';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatCardModule, MatInputModule, MatProgressSpinnerModule, MatSelectModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfimerComponent } from './confimer/confimer.component';
import { SessionLogoutComponent } from './session-logout/session-logout.component';
import { ReloadComponent } from './reload/reload.component';

@NgModule({
    declarations: [
        DialogComponent,
        ConfimerComponent,
        SessionLogoutComponent,
        ReloadComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        FlexLayoutModule,
        ReactiveFormsModule
    ],
    entryComponents: [
        DialogComponent,
        ReloadComponent
    ],
})
export class DialogModule
{
}
